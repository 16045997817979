import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import "./styles/navbar.scss"
import "./styles/global.scss"
import "./styles/fonts.css"
import useWindowDimensions from "./hooks/useWindowDimension"
import Logo from './images/icon-text.png'

const Base = ({ title, children }) => {
  const [toggleNav, setToggleNav] = useState(false)
  const { width } = useWindowDimensions()
  useEffect(() => {
    document.body.style.overflowY = toggleNav ? "hidden" : "auto"
  }, [toggleNav])

  useEffect(() => {
    if (width > 700 && toggleNav) setToggleNav(false)
  }, [width, setToggleNav, toggleNav])

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={title}
        link={[
          {
            rel: "stylesheet",
            href: "https://unpkg.com/purecss@2.0.5/build/base-min.css",
            integrity:
              "sha384-8n6q//u53Vksb5vdkRjqzE3ZnihwvYUnr9YNIIR84CDEk+7bibgoKYjxHuYRWvAB",
            crossorigin: "anonymous",
          },
          {
            rel: "stylesheet",
            href: "https://unpkg.com/purecss@2.0.5/build/menus-min.css",
            integrity:
              "sha384-84Phvfm8qwCxiY50s7KEc0uUVFZotIw96yLjcAj1ZfiYB0tP0PJb/b2pCR5+0afI",
            crossorigin: "anonymous",
          },
          {
            rel: "stylesheet",
            href:
              "https://unpkg.com/purecss@2.0.5/build/grids-responsive-min.css",
            integrity:
              "sha384-v9j5UquTgytWMA7lWRzeVQEq4WJByBDjdJmERJAD4L/YzGPIe40y2+FPgkT4h1C8",
            crossorigin: "anonymous",
          },
          {
            rel: "stylesheet",
            href: "https://unpkg.com/purecss@2.0.5/build/grids-min.css",
            integrity:
              "sha384-hYnEdZbnIdW03uPfVW97cFj0BSEVxle50172mbgWa7G+CH1QXmL7gGm6IKL0pNMm",
            crossorigin: "anonymous",
          },
          {
            rel: "stylesheet",
            href: "https://unpkg.com/purecss@2.0.5/build/forms-min.css",
            integrity:
              "sha384-v6w/lm9J22fUMSplPh+IDbfr0YPWfnQNwxyvSoORoP4nau6vCsskKFWQZWudpY17",
            crossorigin: "anonymous",
          },
        ]}
        meta={[
          {
            name: `description`,
            content:
              "Pine financials overview website. Find out who we are and what we do",
          },
          {
            name: `theme-color`,
            content: `#fff`,
          },
        ]}
      />
      <div className={`site-wrapper ${toggleNav ? `navbar-open` : ``}`}>
        <header className="navbar">
          <div className="navbar-container">
            <button
              className={`hamburger hamburger--squeeze ${
                toggleNav ? `is-active` : ``
              }`}
              onClick={() => setToggleNav(!toggleNav)}
              type="button"
              aria-label="Toggle navigation"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <div className="pure-u-1-5 navbar-left">
              <nav className="pure-menu pure-menu-horizontal">
                <ul className="pure-menu-list">
                  <li className="pure-menu-item">
                    <Link
                      to="/"
                      className={`pure-menu-link ${
                        title === "Home" ? "pure-menu-selected" : ""
                      }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="pure-menu-item">
                    <Link
                      to="/contact"
                      className={`pure-menu-link ${
                        title === "Contact" ? "pure-menu-selected" : ""
                      }`}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="pure-u-3-5">
              <div className="navbar-center">
                <Link className="navbar-logo" to="/">
                  <img src={Logo} className="logo"/>
                </Link>
              </div>
            </div>
            <div className="pure-u-1-5 navbar-right" />
          </div>
        </header>
        <main className="main-content">{children}</main>
        <footer>
          &copy; {new Date().getFullYear()} PINE FINANCIAL LIMITED
        </footer>
      </div>
    </>
  )
}

export default Base
